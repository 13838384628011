.custom-datepicker {
  height: 40px;
  padding: 10px; /* Adjusted padding */
  font-size: 12px; /* Improved readability */
  border: 1px solid #ccc;
  border-radius: 4px;
  box-sizing: border-box;
  width: 360px !important;
}

.custom-datepicker input {
  height: 100%;
  padding: 0;
  font-size: inherit;
  border: none;
  outline: none;
}
  